import Vue from 'vue'

const PageData = {
  result: {},
  loadCategories () {
    return Vue.axios
      .get('/templates/categories', { params: { no_pages: 1, active: 1 } })
      .then((res) => {
        const { data: { data } } = res
        this.result.categories = data.map((category) => { return { id: category.id, name: category.name } })
      })
  },
  loadTypes () {
    return Vue.axios
      .get('/templates/types')
      .then((res) => {
        const { data: { data } } = res
        this.result.types = data.map((type) => { return { id: type.id, name: type.name } })
      })
  },
  loadShortcodes () {
    return Vue.axios
      .get('/macros/shortcodes')
      .then((res) => {
        const { data: { data } } = res
        this.result.shortcodes = data.map((code) => { return { code: code.code, name: code.title, descr: code.descr } })
      })
  },
  loadSubjectCats: function () {
    return Vue.axios
      .get('/subjects/categories', { params: { no_pages: 1, active: 1 } })
      .then((res) => {
        const { data: { data } } = res
        this.result.subject_categories = data.map((category) => { return { id: category.id, name: category.name } })
      })
  },
  loadLayouts () {
    return Vue.axios
      .get('/templates/layouts', { params: { no_pages: 1 } })
      .then((res) => {
        const { data: { data } } = res

        this.result.layouts = data.map((layout) => { return { id: layout.id, name: layout.name } })
      })
  },
  loadAll: function () {
    return Promise.all([
      this.loadShortcodes(),
      this.loadSubjectCats(),
      this.loadCategories()
    ])
  },
  setResult: function (result) {
    this.result = result
    return this
  }
}

export default PageData
