<template>
  <row>
    <column-input :md="12" :sm="12">
      <form-group-textarea name="content" :rows="20" :model="props.formData.content" :max="maxLength">
        <template slot="info">
          <p>
            <span>Shortcodes:</span>
            <code v-for="(shortcode, i) in props.shortcodes" :key="`shortcode-${i}`">{{ shortcode.code }}</code>
          </p>
        </template>
        <p slot="help">* {{ maxLength }} characters max.</p>
      </form-group-textarea>
    </column-input>
  </row>
</template>

<script>
import FormGroupTextarea from '@/views/components/form/FormGroupTextarea'

export default {
  name: 'TemplatesContentText',
  data () {
    return {
      maxLength: 200000
    }
  },
  props: {
    props: Object
  },
  components: {
    FormGroupTextarea
  }
}
</script>
