<template>
  <row>
    <column-input :md="12" :sm="12">
      <row>
        <column-input>
          <form-group-select :disabled="!data.layouts.length || layoutExist" :model="props.formData.layout_id" :options="data.layouts"
                             name="layout_id" required title="Layout"/>
        </column-input>
      </row>
      <row v-if="externalLoad && !externalInit">
        <column-info :md="12" :sm="12" class="block-loader" title="Content">
          <loader/>
        </column-info>
      </row>
      <row v-show="externalInit">
        <column-info :md="12" :sm="12" title="Content">
          <span>Shortcodes:</span>
          <code v-for="(shortcode, i) in props.shortcodes" :key="`shortcode-${i}`">{{ shortcode.code }}</code>
        </column-info>
      </row>
      <row v-show="!externalLoad || externalInit">
        <column-input :md="3" :sm="12" class="external-column">
          <div id="stripoSettingsContainer"/>
        </column-input>
        <column-input :md="9" :sm="12" class="external-column">
          <div id="stripoPreviewContainer"/>
        </column-input>
      </row>
    </column-input>
  </row>
</template>

<script>
import Loader from '@/views/components/simple/Loader'
import FormGroupSelect from '@/views/components/form/FormGroupSelect'
import PageData from '@/views/pages/templates/partials/forms/PageData'
import Alert from '@/modules/utils/Alert'

export default {
  name: 'TemplatesContentStripo',
  computed: {
    layout () { return this.props.formData?.layout_id.value?.id }
  },
  watch: {
    layout () { this.getLayout() },
    externalLoad () { this.emitExternalLoad() }
  },
  data () {
    return {
      layoutExist: false,
      externalLoad: false,
      externalInit: false,
      data: {
        layouts: []
      }
    }
  },
  props: {
    props: Object
  },
  components: {
    Loader,
    FormGroupSelect
  },
  created () {
    if (this.props.formData.content_html && this.props.formData.content_css && this.props.layout) {
      this.layoutExist = true
      this.props.formData.layout_id.value = { id: this.props.layout.id, name: this.props.layout.name }
    } else {
      PageData.setResult(this.data)
      PageData.loadLayouts().then()
    }
  },
  methods: {
    initStripoEmail (id, html, css) {
      this.$loadScript('https://plugins.stripo.email/static/latest/stripo.js')
        .then(() => {
          const self = this

          window.Stripo.init({
            settingsId: 'stripoSettingsContainer',
            previewId: 'stripoPreviewContainer',
            html: html,
            css: css,
            apiRequestData: {
              emailId: 'template_' + id
            },
            getAuthToken: (callback) => {
              self.getTokenStripo(token => { callback(token) })
            },
            onTemplateLoaded: () => {
              self.externalLoad = false
              self.externalInit = true
            }
          })
        })
        .catch(() => {})
    },
    getTokenStripo (callback) {
      this.$http
        .get('/templates/stripo/token')
        .then((res) => {
          const { data: { data } } = res

          callback(data.token)
        })
    },
    preSendCallable (callback) {
      this.externalLoad = true
      Promise.all([
        this.setContents(),
        this.setCompiledTemplate()
      ]).then(() => {
        callback()
      }).catch((error) => {
        Alert.toast({ icon: 'error', title: error })
      }).finally(() => {
        this.externalLoad = false
      })
    },
    setCompiledTemplate () {
      return new Promise((resolve, reject) => {
        window.StripoApi.compileEmail((error, html) => {
          if (!error && html) {
            this.props.formData.content_compiled.value = html
            resolve()
          } else {
            reject(new Error('Get compiled html'))
          }
        })
      })
    },
    setContents () {
      return new Promise((resolve, reject) => {
        window.StripoApi.getTemplate((html, css) => {
          if (html?.length > 0 && css?.length > 0) {
            this.props.formData.content_html.value = html
            this.props.formData.content_css.value = css
            resolve()
          } else {
            reject(new Error('Get html and css'))
          }
        })
      })
    },
    getLayout () {
      this.externalLoad = true
      this.externalInit = false

      this.layoutExist
        ? this.initStripoEmail(this.id, this.props.formData.content_html.getValue(), this.props.formData.content_css.getValue())
        : this.$http
          .get(`/templates/layouts/${this.layout}`)
          .then((res) => {
            const { data: { data } } = res

            this.initStripoEmail(this.id, data.content_html, data.content_css)
          })
    },
    emitExternalLoad () {
      this.$emit('externalLoad', this.externalLoad)
    }
  }
}
</script>
