<template>
  <div>
    <portal to="page-top-title">Edit Template #{{ id }}</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'templates-single', params: { id: id } }" icon="fa-eye" text>Back to Template</btn>
    </portal>
    <box-form action="edit" data-prop="formData" :url="`/templates/${id}`" redirect="templates-list"
              :loaded="loaded" :preSendCallable="preSendCallable" :disabledSubmit="disabledSubmit" loader>
      <template slot="content">
        <row>
          <column-input>
            <form-group-input name="name" :model="formData.name" :min="3" required/>
          </column-input>
          <column-input>
            <form-group-select name="category_id" title="Category" :model="formData.category_id"
                               :options="data.categories" :disabled="!data.categories.length" required/>
          </column-input>
          <column-input>
            <form-group-select name="subject_categories" :model="formData.subject_categories"
                               :options="data.subject_categories" :disabled="!data.subject_categories.length" multiple required/>
          </column-input>
        </row>
        <row>
          <column-input>
            <form-group-select name="type" title="Type" :model="formData.type" :options="data.types" required disabled/>
          </column-input>
          <column-input :md="2">
            <form-group-check name="active" :model="formData.active" :disabled="!editable.active"/>
          </column-input>
        </row>
        <component ref="refContent" :is="typeContent" :props="getPropsContent()" @externalLoad="externalLoad" v-cloak/>
      </template>
    </box-form>
  </div>
</template>

<script>
import BoxForm from '@/views/components/data/BoxForm'
import Btn from '@/views/components/simple/Btn'
import Loader from '@/views/components/simple/Loader'
import FormGroupInput from '@/views/components/form/FormGroupInput'
import FormGroupCheck from '@/views/components/form/FormGroupCheck'
import FormGroupSelect from '@/views/components/form/FormGroupSelect'
import FormGroupTextarea from '@/views/components/form/FormGroupTextarea'
import TemplatesContentText from '@/views/pages/templates/partials/content/Text'
import TemplatesContentStripo from '@/views/pages/templates/partials/content/Stripo'
import Form from '@/modules/utils/Form'
import PageData from '@/views/pages/templates/partials/forms/PageData'

export default {
  name: 'TemplatesEdit',
  metaInfo () {
    return { title: `Template #${this.id} | Edit` }
  },
  components: {
    BoxForm,
    Btn,
    Loader,
    FormGroupInput,
    FormGroupCheck,
    FormGroupSelect,
    FormGroupTextarea,
    TemplatesContentText,
    TemplatesContentStripo
  },
  computed: {
    id () { return this.$route.params.id },
    typeContent () { return this.typesContent[this.formData.type.getValue()] }
  },
  data () {
    return {
      idText: 1,
      loaded: false,
      disabledSubmit: false,
      layout: null,
      editable: {},
      formData: {
        name: Form.types.input(),
        category_id: Form.types.select(),
        subject_categories: Form.types.multiSelect(),
        layout_id: Form.types.select(),
        type: Form.types.select(),
        active: Form.types.boolean(),
        content: Form.types.input(),
        content_html: Form.types.input(),
        content_css: Form.types.input(),
        content_compiled: Form.types.input()
      },
      typesContent: {
        1: 'TemplatesContentText',
        10: 'TemplatesContentStripo'
      },
      data: {
        categories: [],
        shortcodes: [],
        types: [],
        subject_categories: []
      }
    }
  },
  created () {
    PageData.setResult(this.data)
    PageData.loadAll().then()
    this.loadEntity()
  },
  methods: {
    externalLoad (status) {
      this.disabledSubmit = status
    },
    getPropsContent () {
      return {
        formData: this.formData,
        layout: this.layout,
        shortcodes: this.data.shortcodes
      }
    },
    loadEntity () {
      this.$http
        .get(`/templates/${this.id}`)
        .then((res) => {
          const { data: { data } } = res

          this.formData.name.value = data.name
          this.formData.active.value = data.active
          this.formData.category_id.value = { id: data.category.id, name: data.category.name }
          this.formData.subject_categories.value = data.subjectCategories
          this.formData.content.value = data.content
          this.formData.content_html.value = data.content_html
          this.formData.content_css.value = data.content_css
          this.formData.content_compiled.value = data.content_compiled
          this.formData.type.value = { id: data.type, name: data.type_text }
          this.editable = data.metadata.editable
          this.layout = data.layout

          this.loaded = true
        })
    },
    preSendCallable (callback) {
      this.$refs.refContent.preSendCallable
        ? this.$refs.refContent.preSendCallable(() => { callback() })
        : callback()
    }
  }
}
</script>
